import $ from 'jquery';

$(window).on('resize', () => {
	let hBlock2 = $('.js-height-block').height();
	let totalHeight = hBlock2 - 33;
	if ($(window).outerWidth() > 768) {
		$('.c-sns2').css({
			height: totalHeight,
		});
	}
});

$(window).on('load', () => {
	let hBlock2 = $('.js-height-block').height();
	let totalHeight = hBlock2 - 33;
	if ($(window).outerWidth() > 768) {
		$('.c-sns2').css({
			height: totalHeight,
		});
	}
});
